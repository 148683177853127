import {
  ConversionRate,
  DeviceStatus,
  DeviceType,
  TwoFaStep,
  TwoFactorAuthStatus,
  TwoFactorAuthStep,
} from './types';


export const defaultErrorMessage =
  'An unexpected error occured. Please try again';

export const EMAIL_PATTERN = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const DEFAULT_CONVERSION_RATE: ConversionRate = {
  rate: 0,
  amount: 1,
  sourceCurrencyCode: 'GBP',
  destinationCurrencyCode: 'NGN',
};

export const mockStart2FasResponse = {
  sumsubAccessToken:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJodHRwczovL2V4YW1wbGUuYXV0aDAuY29tLyIsImF1ZCI6Imh0dHBzOi8vYXBpLmV4YW1wbGUuY29tL2NhbGFuZGFyL3YxLyIsInN1YiI6InVzcl8xMjMiLCJpYXQiOjE0NTg3ODU3OTYsImV4cCI6MTQ1ODg3MjE5Nn0.CA7eaHjIHz5NxeIJoFK9krqaeZrPLwmMmgI_XiQiIkQ',
  faceMatchVerificationId: 'xyz',
};

export const mockUseComplete2faStepPayload = {
  token: 'xyz',
};

export const mockCreate2faSessionResponse = {
  id: 'xyz',
  deviceInfo: {
    deviceId: 'xyz',
    type: 'WEB' as DeviceType,
    manufacturer: 'Apple',
    model: 'MacIntel',
    username: '+447911123456',
    isKnown: true,
    status: 'ACTIVE' as DeviceStatus,
  },
  mfaAction: 'LOGIN',
  username: '+447911123456',
  requiredSteps: ['OTP'] as TwoFactorAuthStep[],
  expiryInSeconds: 300,
  steps: [
    {
      step: 'OTP',
      status: 'CREATED',
    },
  ] as TwoFaStep[],
  status: 'PENDING' as TwoFactorAuthStatus,
};

export const mock2FasByIdResponse = {
  id: 'xyz',
  deviceInfo: {
    deviceId: 'xyz',
    type: 'ANDROID' as DeviceType,
    manufacturer: 'Samsung',
    model: 'Galaxy S10',
    username: '+449167798322',
    isKnown: true,
    status: 'ACTIVE' as DeviceStatus,
  },
  mfaAction: 'LOGIN',
  username: '+449167798322',
  requiredSteps: ['OTP'] as TwoFactorAuthStep[],
  expiryInSeconds: 300,
  steps: [
    {
      step: 'OTP',
      status: 'CREATED',
    },
  ] as TwoFaStep[],
  status: 'PENDING' as TwoFactorAuthStatus,
};

export const mockCreate2faSessionPayload = {
  deviceInfo: {
    deviceId: 'xyz',
    type: 'WEB' as DeviceType,
    manufacturer: 'Apple',
    model: 'MacIntel',
  },
  mfaAction: 'LOGIN',
  username: '+447911123456',
  requiredSteps: ['OTP'] as TwoFactorAuthStep[],
  expiryInSeconds: 300,
};
